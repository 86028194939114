function startTime()
{
var today=new Date()
var h=today.getHours()
var m=today.getMinutes()
var s=today.getSeconds()
var ap="قبل از ظهر";

//to add AM or PM after time

if(h>11) ap="بعدازظهر";
if(h>12) h=h-12;
if(h==0) h=12;

//to add a zero in front of numbers<10

m=checkTime(m)
s=checkTime(s)

document.getElementById('clock').innerHTML=s+" : "+m+" : "+h+" "+ap
t=setTimeout('startTime()', 500)
}

function checkTime(i)
{
if (i<10)
{ i="0" + i}
return i
}

window.onload=startTime;







//Nav 

// Adds and removes affix class depending on screen width.
function screenClass() {
    if($(window).innerWidth() > 768) {
        $('.navbar.navbar-default').removeClass('position-relative');
    } else {
        $('.navbar.navbar-default').addClass('position-relative');
    }
}

// Fire.
screenClass();

// And recheck if window gets resized.
$(window).bind('resize',function(){
    screenClass();
});
